<template>
  <div class=" w-165 px-4 py-8 border-r">
    <div
      v-for="nav in navList"
      :key="nav.path"
      class="rounded-md h-32 cursor-pointer space-x-8 flex justify-start items-center  box-border py-4 px-8 mb-8"
      :class="{
        'bg-warnings': iscurrentRoute(nav.path),
        'text-warning': iscurrentRoute(nav.path),
      }"
      @click="jump(nav.path)"
    >
      <i
        v-if="iscurrentRoute(nav.path)"
        class="iconfont text-warning"
        :class="[nav.icon]"
      ></i>
      <i v-else class="iconfont text-gray-900" :class="[nav.icon]"></i>
      <span>{{ nav.label }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          label: "账号管理",
          icon: "icon-fill_id",
          path: "account",
        },
        {
          label: "地址管理",
          icon: "icon-fill_site",
          path: "address",
        },
        // {
        //   label: '消息通知',
        //   icon: 'icon-fill_message',
        //   path: 'message'
        // },
        {
          label: "我的求购",
          icon: "icon-a-fill_asktobuy",
          path: "need",
        },
        {
          label: "我关注的店铺",
          icon: "icon-fill_attention",
          path: "focus",
        },
        {
          label: "我收藏的商品",
          icon: "icon-fill_collect",
          path: "collection",
        },
        {
          label: "浏览记录",
          icon: "icon-fill_record",
          path: "history",
        },
      ],
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      await this.$store.dispatch("getProfile");
      this.navList.forEach((p) => {
        if (p.path == "focus") {
          p.label += `(${this.$store.state.profile?.followStores})`;
        }
        if (p.path == "collection") {
          p.label += `(${this.$store.state.profile?.collectibleGoods})`;
        }
      });
    },
    iscurrentRoute(path) {
      return this.$route.path.indexOf(path) > -1;
    },
    jump(path) {
      this.$router.push(path);
    },
  },
};
</script>
