<template>
  <div class="width100 min-height500 height100 manage-center">
    <Header />
    <div
      class="height100 overflow-hidden background-color26 manage-center-box flex flex-direction-column"
    >
      <div
        class="flex1 flex flex-col flex-center content-box margin-auto box-sizing margin-top2 margin-bottom2  box-shadow10 border-radius1 background-color10 overflow-hidden"
      >
        <!-- 未认证用户 -->
        <div v-if="$store.state.profile.companyId === 0" class="padding2 width100">
          <div
            class="hasnoCompany width100 margin-auto flex align-items-center padding2 fontSize14 border-radius1"
          >
            <img src="@/assets/home/warning.png" alt="warning" />
            <span class="margin-left2"
              >认证为企业用户即可入驻平台,入驻后可在管理中心上传产品库存价格!</span
            >
            <span class="margin-left2 cursor-pointer textColor23" @click="auth">
              前往认证 >
            </span>
          </div>
        </div>
        <div
          class="flex1 flex width100 box-sizing padding2  overflow-hidden"
        >
          <div class="height100">
            <SideNav class="height100" />
          </div>
          <div class="flex1 height100 margin-left3 overflow-auto">
            <router-view v-if="!$route.meta.keepAlive"></router-view>
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
          </div>
          <IconChat />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import SideNav from "./components/SideNav.vue";
import IconChat from "@/components/iconChat/iconChat.vue";
import { STATUS } from "@/store/index";

export default {
  name: "ManageCenter",
  components: {
    Header,
    SideNav,
    IconChat,
  },
  data() {
    return {
      isCollapse: true,
    };
  },
  methods: {
    isCollapseChange(e) {
      this.isCollapse = e;
    },
    auth() {
      this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.manage-center .manage-center-box {
  height: calc(100% - 32px) !important;
}
.hasnoCompany {
  border: 1px solid #ff6e4c;
  background-color: #ffeeeb;
  img {
    width: 16px;
    height: 16px;
  }
}
</style>
